<template>
    <vx-card>
        <div
            slot="no-body"
            :style="{
                background: `rgba(var(--vs-success),.70)`,
            }"
        >
            <div class="vx-row no-gutter justify-center p-12">
                <div class="text-center">
                    <h1 class="text-center font-bold text-white">
                        <feather-icon
                            :icon="'FilePlusIcon'"
                            class="p-3 inline-flex rounded-full"
                            :class="[`text-white`, { 'mb-4': true }]"
                            :style="{
                                background: `rgba(var(--vs-success),.4)`,
                            }"
                        ></feather-icon>
                        Start Will
                    </h1>
                </div>
            </div>
            <div class="vx-row no-gutter w-full">
                <div class="vx-col w-full d-theme-dark-bg p-12">
                    <div style="height: 100%" v-if="handoverDetails === null">
                        <div class="vx-row">
                            <div class="vx-col w-full">
                                <vs-input
                                    @keyup.enter="createInitialAccount"
                                    name="email"
                                    icon-no-border
                                    icon="icon icon-mail"
                                    icon-pack="feather"
                                    label="Client Email"
                                    v-model="form.email"
                                    :size="size"
                                    class="w-full"
                                    :danger="errors.email !== null"
                                    :danger-text="errors.email"
                                    description-text="This will be used as the clients username"
                                />
                            </div>
                        </div>
                        <div class="flex flex-row-reverse">
                            <vs-button
                                class="w-full xl:w-auto mt-5"
                                :disabled="!form.email"
                                color="success"
                                :size="size"
                                @click="createInitialAccount"
                                >Start</vs-button
                            >
                        </div>
                    </div>

                    <vs-alert v-if="handoverDetails" active="true">
                        To create a mirror will for
                        <span class="font-bold">{{
                            handoverDetails.partner_full_name
                        }}</span>
                        based upon
                        <span class="font-bold"
                            >{{ handoverDetails.full_name }}'s</span
                        >
                        Will, enter email address below
                    </vs-alert>
                    <div v-if="handoverDetails" class="vx-row mt-5">
                        <div class="vx-col w-3/4">
                            <vs-input
                                @keyup.enter="createPartnerAccount"
                                name="email"
                                icon-no-border
                                icon="icon icon-mail"
                                icon-pack="feather"
                                :label="
                                    handoverDetails.partner_full_name +
                                    '\'s email'
                                "
                                v-model="form.email"
                                :size="size"
                                class="w-full"
                                :danger="errors.email !== null"
                                :danger-text="errors.email"
                            />
                        </div>
                    </div>
                    <div
                        v-if="handoverDetails"
                        class="flex justify-between mt-4"
                    >
                        <vs-button
                            type="flat"
                            color="success"
                            class="w-full xl:w-auto mt-5"
                            @click="clearHandoverData"
                            >Start a new will</vs-button
                        >
                        <vs-button
                            :disabled="!form.email"
                            color="success"
                            class="w-full xl:w-auto mt-5"
                            @click="createPartnerAccount"
                            >Start Mirror</vs-button
                        >
                    </div>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import { http } from '@/services'
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'HandoverCard',
    mixins: [textFormat],
    mounted() {
        if (this.$route.params.email) {
            this.handoverCreatedDetailsFetch(this.$route.params.email)
        } else if (this.$store.getters.initialHandoverUser) {
            this.handoverCreatedDetailsFetch(
                this.$store.getters.initialHandoverUser
            )
        }
        this.$root.$on('createMirrorWill', this.handoverCreatedDetailsFetch)
    },
    data() {
        return {
            form: {
                email: null,
            },
            errors: {
                email: null,
            },
            handoverDetails: null,
        }
    },
    computed: {
        isValidated() {
            return !!this.form.email
        },
        subTitle() {
            if (
                this.handoverDetails &&
                this.handoverDetails.partner_full_name
            ) {
                return `Mirror will for ${this.handoverDetails.partner_full_name}`
            }
            return 'New client'
        },
        size() {
            return this.$store.getters.windowBreakPoint === 'xs'
                ? 'large'
                : null
        },
    },
    methods: {
        registerAccount(data) {
            http.post('broker_create_user_account_main', data)
                .then((response) => {
                    if (response.data.new_account) {
                        this.$vs.notify({
                            title: 'Success',
                            text: 'New account created',
                            color: 'success',
                            icon: 'feather',
                            iconPack: 'icon-send',
                            position: 'top-center',
                        })
                    } else {
                        this.$vs.notify({
                            title: 'Success',
                            text: 'Continuing with existing account',
                            color: 'success',
                            icon: 'feather',
                            iconPack: 'icon-send',
                            position: 'top-center',
                        })
                    }
                    this.$store.commit('initialHandoverUser', this.form.email)
                    this.loginAsUser(response.data)
                })
                .catch((error) => {
                    console.log(error)
                    this.handleErrors(error.response.data)
                })
        },
        createInitialAccount() {
            this.clearErrors()
            var data = {
                email: this.form.email,
            }
            this.registerAccount(data)
        },
        createPartnerAccount() {
            this.clearErrors()
            var data = {
                email: this.form.email,
                mirror_will_user: this.handoverDetails.user.email,
            }
            this.registerAccount(data)
        },
        handleErrors(errors) {
            if (errors.email) this.errors.email = errors.email[0]
            else {
                console.log(errors)
            }
        },
        clearErrors() {
            this.errors.email = null
        },
        loginAsUser(data) {
            if (data.access && data.refresh) {
                var base =
                    process.env.NODE_ENV === 'production'
                        ? 'https://wills.mylastwill.co.uk/login/'
                        : 'http://127.0.0.1:7000/login/'
                var url = base + data.access + '/' + data.refresh
                var win = window.open(url, '_self')
                win.focus()
            }
        },
        handoverCreatedDetailsFetch(email) {
            http.get('handover_created_details_main', {
                params: {
                    user_email: email,
                },
            })
                .then((response) => {
                    if (
                        response.data.partner_full_name &&
                        response.data.partner === null
                    ) {
                        this.handoverDetails = response.data
                    } else {
                        this.clearHandoverData()
                    }

                    if (this.handoverDetails.partner_email) {
                        this.form.email = this.handoverDetails.partner_email
                    } else {
                        this.errors.email = 'Enter Partners Email Address'
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        clearHandoverData() {
            this.$router.push({ name: 'handover' }).catch((e) => console.log(e))
            this.$store.commit('initialHandoverUser', null)
            this.handoverDetails = null
            this.form.email = null
            this.clearErrors()
        },
    },
}
</script>
