<template>
    <vs-table
        ref='table'
        v-model='selected'
        :data='accounts'
        :onlyClickCheckbox='true'
        :sst='true'
    >
        <div slot='header' class='flex items-center flex-grow justify-end'>
            <div>
                <vs-input v-model='search' icon='search' placeholder='Search' />
            </div>
        </div>

        <template slot='thead'>
            <vs-th>Date Created</vs-th>
            <vs-th>Details</vs-th>
            <vs-th>Share details</vs-th>
            <vs-th>Actions</vs-th>
        </template>

        <template slot-scope='{ data }'>
            <tbody>
            <vs-tr v-for='(user, index) in data' :key='index' :data='user'>
                <vs-td>
                    {{ formatDateAndTime(user.date_joined) }}
                </vs-td>
                <vs-td>
                    {{ user.email }} <br>
                    {{ user.personal_details.full_name }}<br />
                    <small
                    >{{ user.personal_details.address_line_1 }},
                        {{ user.personal_details.post_code }}</small
                    ><br>
                    <small>Partner Name: {{ user.personal_details.partner_full_name }}</small>
                </vs-td>

                <vs-td>
                       <p v-if="user.share_people.length"> <small
                            v-for='(person, index) in user.share_people'
                            :key='index'
                        >
                           {{ person.full_name }} - {{ person.email }} {{ person.phone }}
                        </small></p>
                    <p v-else>No client share details collected</p>
                </vs-td>
                <vs-td>
                    <div class='flex justify-end row-hidden-elements'>
                        <vx-tooltip
                            v-if='!user.temp_account'
                            color='primary'
                            text='Re-send handover email & sms'
                            title='Send'
                        >
                            <vs-button
                                icon='icon-send'
                                icon-pack='feather'
                                type='flat'
                                @click="$emit('send', user)"
                            ></vs-button>
                        </vx-tooltip>
                        <vx-tooltip
                            color='success'
                            text='Resend client contact share details to yourself (the broker). These are the contact details of the people from within their will.'
                            title='Send'
                        >
                            <vs-button
                                color='primary'
                                icon='icon-share'
                                icon-pack='feather'
                                type='flat'
                                @click="$emit('sendBrokerShare', user)"
                            ></vs-button>
                        </vx-tooltip>

                        <vx-tooltip
                            color='primary'
                            text='Edit clients Will'
                            title='Login'
                        >
                            <vs-button
                                icon='icon-log-in'
                                icon-pack='feather'
                                type='flat'
                                @click="$emit('login', user)"
                            ></vs-button>
                        </vx-tooltip>
                        <vx-tooltip
                            color='primary'
                            text='Email address'
                            title='Edit'
                        >
                            <vs-button
                                icon='icon-edit'
                                icon-pack='feather'
                                type='flat'
                                @click="$emit('edit', user)"
                            ></vs-button>
                        </vx-tooltip>


                        <vx-tooltip
                            v-if='
                                    user.personal_details.partner_full_name &&
                                    !user.personal_details.partner
                                '
                            color='success'
                            text='Partner Account'
                            title='Create'
                        >
                            <vs-button
                                color='success'
                                icon='icon-users'
                                icon-pack='feather'
                                type='flat'
                                @click='createMirrorWill(user)'
                            ></vs-button>
                        </vx-tooltip>
                        <vx-tooltip
                            color='secondary'
                            text='User account'
                            title='Delete'
                        >
                            <vs-button
                                color='secondary'
                                icon='icon-trash'
                                icon-pack='feather'
                                type='flat'
                                @click="$emit('delete', user)"
                            ></vs-button>
                        </vx-tooltip>
                    </div>
                </vs-td>


            </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'HandoverTable',
    mixins: [textFormat],
    props: ['data', 'itemsPerPage', 'view'],
    computed: {
        accounts() {
            return this.data.data
        },
        search: {
            set(value) {
                if (value.length > 1 || value === '') {
                    this.searchQuery = value
                    this.$emit('search', value)
                }
            },
            get() {
                return this.searchQuery
            },
        },
    },
    data() {
        return {
            selected: [],
            searchQuery: '',
            status: 'All',
            selectMultiple: false,
        }
    },
    methods: {
        toggleSelect() {
            this.selected = []
            this.selectMultiple = !this.selectMultiple
        },
        setFilter(status, value) {
            this.status = status
            this.$emit('filter', value)
        },
        createMirrorWill(user) {
            this.$root.$emit('createMirrorWill', user.email)
            window.scrollTo(0, 0)
        },
    },
}
</script>

<style>
.row-hidden-elements {
    visibility: hidden;
}

.vs-table tr:hover .row-hidden-elements {
    visibility: visible;
}
</style>
