<template>
    <div class="">
        <div class="vx-row">
            <div class="vx-col w-full lg:w-1/3 xl:1/3 mb-base">
                <HandoverCard />
            </div>
        </div>
        <h2 class="mt-2 mb-2">Created Accounts</h2>
        <div
            id="data-list-list-view"
            class="data-list-container"
            v-if="accounts"
        >
            <HandoverTable
                :data="accounts"
                :itemsPerPage="itemsPerPage"
                @itemsPerPage="setItemsPerPage"
                @search="search"
                @filter="setFilter"
                @edit="setEdit"
                @send="confirmSendHandoverComms"
                @sendBrokerShare="confirmSendBrokerShareComms"
                @delete="deleteConfirm"
                @login="confirmLogin"
            ></HandoverTable>
            <div class="paginator-container">
                <vs-pagination
                    description
                    goto
                    descriptionTitle="accounts"
                    :sizeArray="accounts.count"
                    :description-items="descriptionItems"
                    :total="accounts.number_of_pages"
                    :max-items="itemsPerPage"
                    :max="itemsPerPage"
                    v-model="pageNumberComputed"
                    @changeMaxItems="setItemsPerPage"
                ></vs-pagination>
            </div>
        </div>
        <EditModal
            :show-data="show.editModal"
            :log-data="selectedAccount"
            @close="closeEditModal"
            @update="getPage"
        />
    </div>
</template>

<script>
import EditModal from './EditModal'
import HandoverTable from './Table'
import { http } from '@/services'
import HandoverCard from '@/views/main/Handover/HandoverCard'

export default {
    name: 'Handover',
    components: {
        HandoverCard,
        HandoverTable,
        EditModal,
    },
    created() {
        if (this.$route.params.page)
            this.pageNumber = Number(this.$route.params.page)
        this.getPage()
    },
    beforeRouteUpdate(to, from, next) {
        next()
        if (this.$route.params.page)
            this.pageNumber = Number(this.$route.params.page)
        this.getPage()
    },
    watch: {
        pageNumber(newValue, oldValue) {
            if (Number(newValue) !== Number(oldValue))
                this.$router.push({
                    name: 'handover',
                    params: { page: Number(newValue) },
                })
        },
    },
    computed: {
        pageNumberComputed: {
            set(value) {
                this.pageNumber = value
                this.getPage()
            },
            get() {
                return this.pageNumber
            },
        },
    },
    data() {
        return {
            accounts: null,
            pageNumber: 1,
            itemsPerPage: 5,
            selected: null,
            queriedItems: 20,
            searchQuery: null,
            filter: 'all',
            descriptionItems: [5, 10, 20],
            selectedAccount: null,
            show: {
                editModal: false,
            },
        }
    },
    methods: {
        setItemsPerPage(value) {
            this.itemsPerPage = this.descriptionItems[value]
            this.getPage()
        },
        setPageNumber(value) {
            this.pageNumber = value
            this.getPage()
        },
        setView(value) {
            this.searchQuery = null
            this.view = value
            this.getPage()
        },
        getPage() {
            http.get('handover_accounts', {
                params: {
                    filter: this.filter,
                    items_per_page: this.itemsPerPage,
                    page_number: this.pageNumber,
                    query: this.searchQuery,
                    view: this.view,
                },
            })
                .then((response) => {
                    this.accounts = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        search(value) {
            this.searchQuery = value
            this.getPage()
        },
        setFilter(value) {
            this.filter = value
            this.getPage()
        },
        deleteConfirm(acocunt) {
            this.selectedAccount = acocunt
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Delete Account`,
                text: `${this.selectedAccount.email}`,
                accept: this.deleteAccount,
                acceptText: 'Delete',
            })
        },
        deleteAccount() {
            http.delete('handover_accounts', {
                params: { id: this.selectedAccount.id },
            })
                .then((response) => {
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Account Deleted',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-trash',
                        position: 'top-center',
                    })
                    this.getPage()
                    this.selectedAccount = null
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Error',
                        text: error.response.data,
                        color: 'warning',
                        icon: 'feather',
                        iconPack: 'icon-trash',
                        position: 'top-center',
                    })
                    this.selectedAccount = null
                })
        },
        setEdit(value) {
            this.selectedAccount = value
            this.show.editModal = true
        },
        setNew() {
            this.selectedAccount = null
            this.show.editModal = true
        },
        closeEditModal() {
            this.show.editModal = false
            this.selectedAccount = null
        },
        confirmLogin(value) {
            this.selectedAccount = value
            this.$vs.dialog({
                type: 'confirm',
                color: 'success',
                title: `Login to Account?`,
                text: `You can login to the account '${this.selectedAccount.email}'. You can then update the will then re-handover the account to your client`,
                accept: this.loginAsUser,
                acceptText: 'Login',
            })
        },
        loginAsUser() {
            http.post('login_as_user', { id: this.selectedAccount.id })
                .then((response) => {
                    if (response.data.access && response.data.refresh) {
                        var base =
                            process.env.NODE_ENV === 'production'
                                ? 'https://wills.mylastwill.co.uk/login/'
                                : 'http://127.0.0.1:7000/login/'
                        var url =
                            base +
                            response.data.access +
                            '/' +
                            response.data.refresh

                        var win = window.open(url, '_blank')
                        win.focus()
                        this.$vs.notify({
                            title: 'Login Success',
                            text: 'A new tab has opened',
                            color: 'success',
                            icon: 'feather',
                            iconPack: 'icon-user',
                            position: 'top-center',
                        })
                    }
                })
                .catch((error) => {
                    console.log(error.response.data)
                })
        },
        confirmSendHandoverComms(value) {
            this.selectedAccount = value
            this.$vs.dialog({
                type: 'confirm',
                color: 'success',
                title: `Re-send`,
                text: `Do you want to re-send email and sms to '${this.selectedAccount.personal_details.full_name}' (${this.selectedAccount.email} ${this.selectedAccount.personal_details.phone})`,
                accept: this.sendHandoverComms,
                acceptText: 'Send',
            })
        },
        confirmSendBrokerShareComms(value) {
            this.selectedAccount = value
            this.$vs.dialog({
                type: 'confirm',
                color: 'success',
                title: `Re-send`,
                text: `Do you want to re-send email and sms to ${this.$store.getters.user.email}`,
                accept: this.sendBrokerShareComms,
                acceptText: 'Send',
            })
        },
        sendHandoverComms() {
            http.patch('login_as_user', {
                id: this.selectedAccount.id,
            })
                .then((response) => {
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Handover communication sent',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-log-in',
                        position: 'top-center',
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Error',
                        text: error.response.data,
                        color: 'warning',
                        icon: 'feather',
                        iconPack: 'icon-log-in',
                        position: 'top-center',
                    })
                })
        },
        sendBrokerShareComms() {
            http.post('send_broker_share_comms', {
                user_pk: this.selectedAccount.id,
            })
                .then((response) => {
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Share details sent',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-log-in',
                        position: 'top-center',
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Error',
                        text: error.response.data,
                        color: 'warning',
                        icon: 'feather',
                        iconPack: 'icon-log-in',
                        position: 'top-center',
                    })
                })
        }
    },
}
</script>
